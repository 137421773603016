import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'SearchAsset',
  components: {
    DatePicker
  },
  watch: {
    currentPage() {
      this.getAssestsList();
    },
    perPage() {
      this.currentPage = 1;
      this.getAssestsList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      isViewMore: false,
      payload: null,
      assetNo: null,
      oracleId: null,
      description: '',
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: {
        text: null,
        value: null
      },
      location: {
        text: null,
        value: null
      },
      category: {
        text: null,
        value: null
      },
      invoice_num: '',
      type: {
        text: null,
        value: null
      },
      faLocation: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      manufacturer: '',
      serialNo: '',
      modelNo: '',
      purchaseDate: [],
      poNumber: '',
      value: '',
      owned: {
        text: null,
        value: null
      },
      searchAssetData: [],
      searchAssetFields: [
        {
          key: 'doc_num',
          label: 'Tag Number'
        },
        {
          key: 'oracle_id',
          label: 'Asset Id'
        },
        {
          key: 'book_type_code',
          label: 'Book Type'
        },
        {
          key: 'asset_category_name',
          label: 'Category'
        },
        {
          key: 'hr_loc_name',
          label: 'HR Location'
        },
        {
          key: 'fa_loc_name',
          label: 'FA Location'
        },
        {
          key: 'hr_base_loc_name',
          label: 'Base Location'
        },
        {
          key: 'description'
        },
        {
          key: 'asset_status_meaning',
          label: 'Status'
        },
        {
          key: 'purchase_date'
        },
        {
          key: 'actual_cost',
          label: 'Original Cost'
        },
        {
          key: 'po_number'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'hsn_sac_code'
        },
        {
          key: 'asset_type_vset_meaning',
          label: 'Asset Type'
        },
        {
          key: 'manufacturer_name'
        },
        {
          key: 'model_no'
        },
        {
          key: 'serial_no'
        },
        {
          key: 'owned_leased_meaning',
          label: 'Owned Lease'
        },
        {
          key: 'last_trf_date',
          label: 'Last Transfer Date'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'view_history'
        }
      ],
      showExcelUploadModal: false
    };
  },
  validations: {
    bookType: {
      text: {
        required
      }
    },
    legalEntity: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'assets/getAllAssets',
            'assets',
            () => (this.loader = false)
          );
        }
        if (actionName === 'upload') {
          this.showExcelUploadModal = true;
        }
      }
    });
  },
  methods: {
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
    },
    getAssestsList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const searchValid = this.validateSearchFilters();
        if (!searchValid) {
          return alert(
            'Please fill at least one search filter apart from Legal Entity & Book Type'
          );
        }
        this.payload = {
          pagination: {
            _page: this.currentPage - 1,
            _limit: this.perPage
          },
          params: {
            asset_category_id: this.category.value,
            asset_status: this.status.value,
            asset_type_vset: this.type.value,
            book_type_code: this.bookType.value,
            description: this.description,
            le_id: this.legalEntity.value,
            manufacturer_name: this.manufacturer,
            model_no: this.modelNo,
            owned_leased: this.owned.value,
            po_number: this.poNumber,
            purchase_date_from: commonHelper.formattedDate(
              this.purchaseDate[0]
            ),
            purchase_date_to: commonHelper.formattedDate(this.purchaseDate[1]),
            serial_no: this.serialNo,
            //S oracle_id:this.assetNo,
            doc_num: this.assetNo,
            oracle_id: this.oracleId,
            hr_location: this.location.value,
            fa_location: this.faLocation.value
          }
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getAllAssets', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.searchAssetData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => (this.loader = false));
      }
    },
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookType = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.LOCATION:
          this.location = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_CATEGORY:
          this.category = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_TYPE:
          this.type = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_STATUS:
          this.status = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_LOCATION:
          this.faLocation = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_OWNED_TYPE:
          this.owned = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    mainSearch() {
      this.isViewMore = false;
      this.getAssestsList();
    },
    clear() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.bookType = {
        text: null,
        value: null
      };
      this.location = {
        text: null,
        value: null
      };
      this.category = {
        text: null,
        value: null
      };
      this.invoice_num = null;
      this.assetNo = null;
      this.oracleId = null;
      this.description = '';
      this.type = {
        text: null,
        value: null
      };
      this.faLocation = {
        text: null,
        value: null
      };
      this.status = {
        text: null,
        value: null
      };
      this.manufacturer = '';
      this.serialNo = '';
      this.modelNo = '';
      this.purchaseDate = [];
      this.poNumber = '';
      this.value = '';
      this.owned = {
        text: null,
        value: null
      };
      this.totalRows = null;
      this.searchAssetData = [];
    },
    chooseValueset(name) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'book_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'hr_location':
          this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
          break;
        case 'category':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_CATEGORY);
          break;
        case 'type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_TYPE);
          break;
        case 'status':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_STATUS);
          break;
        case 'fa_location':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_LOCATION);
          break;
        case 'owned':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_OWNED_TYPE);
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        case appStrings.VALUESETTYPE.ASSET_CATEGORY:
          this.parent_value_set_id = this.bookType.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    validateSearchFilters() {
      if(
        this.location.value ||
        this.category.value ||
        this.invoice_num ||
        this.assetNo ||
        this.oracleId ||
        this.description ||
        this.type.value ||
        this.faLocation.value ||
        this.status.value ||
        this.manufacturer ||
        this.serialNo ||
        this.modelNo ||
        this.purchaseDate.length ||
        this.poNumber ||
        this.value ||
        this.owned.value
      ) {
        return true;
      } else {
        return false;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.bookType.value) {
        this.bookType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.location.value) {
        this.location = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.category.value) {
        this.category = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.type.value) {
        this.type = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.faLocation.value) {
        this.faLocation = {
          text: null,
          value: null
        };
      }else if (vsetCode === this.owned.value) {
        this.owned = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
